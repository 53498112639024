'use strict';

/**
 * @author TRH
 */

/*** GLOBALT AU OBJEKT ***/

var AU = {
    pageLang: 'da', // Default sprog
    cdn: 'https://cdn.au.dk',
    version: '202204211100',
    bss: false,
    ready: [],
    addReadyFunction: function addReadyFunction(fn) {
        if (typeof fn === 'function') {
            // Check if the function reference already exists in the ready array
            if (AU.ready.indexOf(fn) === -1) {
                AU.ready.push(fn);
            } else {
                console.log("Function already exists in AU.ready and will not be added again.");
            }
        } else {
            console.error("Attempted to add a non-function to AU.ready.");
        }
    },
    load: [],
    addLoadFunction: function addLoadFunction(fn) {
        if (typeof fn === 'function') {
            // Check if the function reference already exists in the load array
            if (AU.load.indexOf(fn) === -1) {
                AU.load.push(fn);
            } else {
                console.log("Function already exists in AU.load and will not be added again.");
            }
        } else {
            console.error("Attempted to add a non-function to AU.load.");
        }
    },
    resize: [],
    addResizeFunction: function addResizeFunction(fn) {
        if (typeof fn === 'function') {
            // Check if the function reference already exists in the resize array
            if (AU.resize.indexOf(fn) === -1) {
                AU.resize.push(fn);
            } else {
                console.log("Function already exists in AU.resize and will not be added again.");
            }
        } else {
            console.error("Attempted to add a non-function to AU.resize.");
        }
    },
    scroll: [],
    addScrollFunction: function addScrollFunction(fn) {
        if (typeof fn === 'function') {
            // Check if the function reference already exists in the scroll array
            if (AU.scroll.indexOf(fn) === -1) {
                AU.scroll.push(fn);
            } else {
                console.log("Function already exists in AU.scroll and will not be added again.");
            }
        } else {
            console.error("Attempted to add a non-function to AU.scroll.");
        }
    },
    navigation: {}
};
'use strict';

/**
 * Titel: HELPERS
 * Beskrivelse: hjælpemetoder som kan anvendes på tværs af alle scripts
 * Status: er kun i mindre grad blevet modificeret fra 2011/2015-udgaven
 */

AU.helpers = {
    getUnicodeKey: function getUnicodeKey(e) {
        var unicode = e.keyCode ? e.keyCode : e.charCode;
        return unicode;
    },
    rewriteLink: function rewriteLink(text) {
        return text.trim().toLowerCase().replace(/ /g, '_').replace(/æ/g, 'ae').replace(/ø/g, 'oe').replace(/å/g, 'aa');
    },
    getQuerystring: function getQuerystring(key, default_) {
        if (!default_) {
            default_ = '';
        }

        key = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + key + "=([^&#]*)");

        var qs = regex.exec(window.location.href);

        if (qs === null) {
            return default_;
        } else {
            return qs[1];
        }
    },
    getInternetExplorerVersion: function getInternetExplorerVersion() {
        var rv = -1;
        if (navigator.userAgent.indexOf('Trident') > -1) {
            var ua = navigator.userAgent;
            var re = new RegExp("rv:([0-9]{1,}[.0-9]{0,})");
            if (re.exec(ua) !== null) {
                rv = parseFloat(RegExp.$1);
            }
        }
        return rv;
    },
    isMobileDevice: function isMobileDevice() {
        return (/Mobile|iP(hone|od|ad)|Android|IEMobile/i.test(navigator.userAgent)
        );
    },
    getXML: function getXML(source, callback) {
        jQuery.ajax({
            type: 'GET',
            url: source,
            dataType: 'xml',
            success: function success(data) {
                if (typeof callback === 'function') {
                    callback(data);
                }
            }
        });
    },
    isElementInViewport: function isElementInViewport(element) {
        if (element === null) {
            return false;
        }

        var rect = element.getBoundingClientRect();

        return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    },
    isElementPartlyInViewport: function isElementPartlyInViewport(element) {
        var top = element.offsetTop;
        var left = element.offsetLeft;
        var width = element.offsetWidth;
        var height = element.offsetHeight;

        while (element.offsetParent) {
            element = element.offsetParent;
            top += element.offsetTop;
            left += element.offsetLeft;
        }

        return top < window.pageYOffset + window.innerHeight && left < window.pageXOffset + window.innerWidth && top + height > window.pageYOffset && left + width > window.pageXOffset;
    },
    trimText: function trimText(label, amount, end) {
        if (label.length > amount) {
            return label.substring(0, amount) + end;
        }

        return label;
    },
    validateEmail: function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    componentToHex: function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    },
    rgbToHex: function rgbToHex(r, g, b) {
        return this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    },
    getPageContent: function getPageContent(url, retrieveSelector, insertSelector, callback) {
        var urlAllowed = function urlAllowed(url) {
            var protocolAndHost = location.protocol + '//' + location.host;
            return url.indexOf(protocolAndHost) === 0;
        };

        var insertElement = jQuery(insertSelector);
        if (urlAllowed(url)) {
            insertElement.load(url + ' ' + retrieveSelector, function () {
                if (typeof callback === 'function') {
                    callback();
                }
            });
            return true;
        } else {
            return false;
        }
    },
    accentMap: {
        'é': 'e',
        'ä': 'æ',
        'ö': 'ø',
        'á': 'a',
        'ü': 'y'
    },
    normalizeAccents: function normalizeAccents(term, accentMap) {
        if (!accentMap) {
            accentMap = this.accentMap;
        }

        var ret = '';
        for (var i = 0; i < term.length; i++) {
            ret += accentMap[term.charAt(i)] || term.charAt(i);
        }
        return ret;
    }
};
'use strict';

/**
 * Titel: NAVIGATION
 * Beskrivelse: initialisering af menuer, fade (som stadig bruges til news), brødkrummer m.m.
 * Status: er kun i mindre grad blevet modificeret fra 2011/2015-udgaven
 */

AU.navigation.menu = {
    setClick: function setClick() {
        jQuery('button.menu-icon').on('click', function () {
            var headerMenu = jQuery(this).parents('.header-menu');
            if (headerMenu.hasClass('open')) {
                headerMenu.removeClass('open');
                if (!AU.bss) {
                    jQuery('.search').removeClass('hide');
                }
            } else {
                headerMenu.addClass('open');
                if (!AU.bss) {
                    jQuery('.search').addClass('hide');
                }
            }
        });
    },
    setUtility: function setUtility() {
        jQuery(document).on('show.zf.dropdown', 'header .dropdown-pane', function () {
            AU.databox.init();
            AU.alphabox.init();
        });
    },
    setBreadcrumb: function setBreadcrumb() {
        var bc = jQuery('#au_breadcrumb');
        var crumbs = jQuery('> a', bc);
        var crumbCutOff = 3;
        if (crumbs.length > crumbCutOff) {
            bc.addClass('collapse');
            crumbs.eq(0).after('<div class="bc-expand">&nbsp;<span class="icon-bullet_rounded"></span>&nbsp;<span>&hellip;</span>&nbsp;<span class="icon-bullet_rounded"></span></div>');
            jQuery('.bc-expand span').on('click', function () {
                bc.toggleClass('collapse');
            });
        }
    },
    addExtraMenuItem: function addExtraMenuItem() {
        // Fix til den mobile menu, hvor der indsættes et ekstra top-level menupunkt
        var topLevelMenuItems = jQuery('.top-bar > div > ul.menu li.has-submenu');
        topLevelMenuItems.each(function () {
            var topLevelMenuItem = jQuery('> a', this);
            var topLevelSubMenu = jQuery('ul.submenu', this);
            topLevelSubMenu.prepend('<li role="menuitem" class="show-for-small-only is-submenu-item is-dropdown-submenu-item is-accordion-submenu-item"><a href="' + topLevelMenuItem.attr('href') + '">' + topLevelMenuItem.text() + '</a></li>');
        });
    },
    setTopBar: function setTopBar() {
        var topBarLinks = jQuery('body:not(.department):not(.neutral) .top-bar .menu > li > a, body.department:not(.omnibus) .top-bar.custom .menu > li > a');
        var headerMenu = jQuery('.header-menu');
        topBarLinks.each(function () {
            var topBarContent = jQuery('.top-bar-content', jQuery(this).parent());
            jQuery(this).on('mouseover', function () {
                if (AU.responsive.state.NAME != 'PHONE' && !AU.helpers.isMobileDevice()) {
                    AU.navigation.topBarTimeout = setTimeout(function () {
                        topBarContent.show().css('width', headerMenu.css('width'));
                    }, 300);
                }
            }).on('mouseout', function () {
                if (AU.responsive.state.NAME != 'PHONE' && !AU.helpers.isMobileDevice()) {
                    topBarContent.hide();
                    clearTimeout(AU.navigation.topBarTimeout);
                }
            }).on('click', function (e) {
                if (AU.helpers.isMobileDevice()) {
                    e.preventDefault();
                    jQuery('.top-bar-content').not(topBarContent).hide();
                    topBarContent.toggle().css('width', headerMenu.css('width'));
                }
            });
        });

        jQuery('.top-bar-content').on('mouseover', function () {
            if (AU.responsive.state.NAME != 'PHONE' && !AU.helpers.isMobileDevice()) {
                jQuery(this).show();
            }
        }).on('mouseout', function () {
            if (AU.responsive.state.NAME != 'PHONE' && !AU.helpers.isMobileDevice()) {
                jQuery(this).hide();
            }
        });

        if (typeof window.selectedUM !== 'undefined' && window.selectedUM !== 100) {
            // 100 = ingenting.
            jQuery('.top-bar .menu > li:eq(' + (window.selectedUM - 1) + ')').addClass('active');
        }
    }
};

AU.navigation.search = {
    setClick: function setClick() {
        jQuery('#tx_googlequery_searchform_ss_q, #cludo-search-form-input').on('focusout', function () {
            jQuery('#tx_googlequery_searchform_ss_q, #cludo-search-form-input').val('');
            jQuery('.section-header').removeClass('opensearch');
        });

        jQuery('.au_searchbox').on('click', function () {
            jQuery('.section-header').addClass('opensearch');
            jQuery('#tx_googlequery_searchform_ss_q, #cludo-search-form-input').focus();
        });
    }
};

AU.navigation.frontpage = {
    setTargetGroups: function setTargetGroups() {
        var buttons = jQuery('.target-groups .button');
        var groups = jQuery('.target-groups + div > div > .csc-default');
        var toggleGroup = function toggleGroup(i) {
            var group = groups.eq(i);
            group.toggleClass('active');
            groups.not(group).removeClass('active');
        };
        buttons.each(function (i, btn) {
            var button = jQuery(btn);
            button.on('click', function (e) {
                e.preventDefault();
                jQuery(this).toggleClass('active');
                buttons.not(button).removeClass('active');
                toggleGroup(i);
            });
        });
    }
};
'use strict';

/**
 * Titel: COOKIES
 * Beskrivelse: Hjælpemetoder til get/set af cookies samt initialisering af cookievarsling
 * Status: er ikke blevet ændret siden 2011-designet. Cookieadvarslen kunne med fordel skrives om til ES6/React
 */

AU.cookies = {
    set: function set(c_name, value, exdays, path) {
        if (typeof path === 'undefined') {
            path = ';path=/;domain=au.dk';
        }

        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = encodeURI(value) + (exdays === null ? '' : '; expires=' + exdate.toUTCString());
        document.cookie = c_name + '=' + c_value + path;
    },
    get: function get(c_name) {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(' ' + c_name + '=');
        if (c_start === -1) {
            c_start = c_value.indexOf(c_name + '=');
        }
        if (c_start === -1) {
            c_value = null;
        } else {
            c_start = c_value.indexOf('=', c_start) + 1;
            var c_end = c_value.indexOf(';', c_start);
            if (c_end === -1) {
                c_end = c_value.length;
            }
            c_value = decodeURI(c_value.substring(c_start, c_end));
        }
        return c_value;
    },
    alertElement: null,
    noAlertPeriod: 365,
    strings: {
        header: '',
        paragraph: '',
        button: ''
    },
    init: function init() {
        if (window.alertCookies) {
            var replaceCookieTexts = function replaceCookieTexts(text) {
                return text.replace('{0}', AU.cookies.strings.header).replace('{1}', AU.cookies.strings.paragraph).replace('{2}', AU.cookies.strings.button);
            };

            var alertElement = document.createElement('div');
            alertElement.setAttribute('id', 'au_cookiesalert');
            alertElement.innerHTML = replaceCookieTexts('<h1>{0}</h1><p>{1}</p><p class="button" id="au_cookiesalert_yes"><button>{2}</button></p>');
            var globalFooter = document.querySelector('footer.global');
            if (globalFooter) {
                globalFooter.insertAdjacentElement('afterend', alertElement);
                alertElement.style.display = 'none';
                this.alertElement = alertElement;
            }

            var allowCookies = this.get('allow_cookies');
            if (allowCookies === null) {
                this.alert();
            }
        }
    },
    initCookieScript: function initCookieScript() {
        if (typeof window.cookieScriptId !== 'undefined') {
            // Insert scripts and CSS for Pop Up
            var head = document.head;
            var cookieScriptManipulationCode = document.createElement('script');
            cookieScriptManipulationCode.setAttribute('src', '//cdn.au.dk/res/cookiescript/cookie-modal.min.js?a=' + (typeof window.cookieScriptVersion !== 'undefined' ? window.cookieScriptVersion : '1'));
            cookieScriptManipulationCode.setAttribute('charset', 'UTF-8');
            cookieScriptManipulationCode.setAttribute('type', 'text/javascript');
            head.insertBefore(cookieScriptManipulationCode, head.firstElementChild);
        }
    },
    alert: function alert() {
        if (this.alertElement !== null) {
            this.alertElement.style.display = 'block';

            document.querySelector('#au_cookiesalert_yes button').addEventListener('click', function (e) {
                e.preventDefault();
                AU.cookies.accept(AU.cookies.noAlertPeriod, true);
            });
        }
    },
    accept: function accept(duration, byGUI) {
        var path = location.host.indexOf('au.dk') > -1 ? undefined : ';path=/';
        this.set('allow_cookies', 'true', duration, path);
        if (byGUI) {
            this.alertElement.style.display = 'none';
        }
    }
};
'use strict';

/**
 * Titel: IPURE
 * Beskrivelse: Globale funktioner til kald af iPure-personsøgning - bruges på kort og i nyt medarbejderdesign
 * Status: er kun i mindre grad blevet modificeret fra 2011/2015-udgaven
 */

AU.ipure = {
    source: {
        searchCrossSite: '//ipure.nfit.au.dk/searchPeople.php?sort=lno&q=',
        searchDetailsCrossSite: '//ipure.nfit.au.dk/current/da_DK/lno/',
        photos: 'http://ipure.nfit.au.dk/current/'
    },
    getPerson: function getPerson(xml) {
        var name = $(xml).find('name');
        var room = name.attr('room');
        var lastname = name.attr('lastName');
        var firstname = name.attr('firstName');
        var details = $(xml).find('details');
        var detailsSource = details.attr('external');

        var person = {
            lastname: lastname,
            firstname: firstname,
            room: room,
            detailsSource: detailsSource
        };

        return person;
    },
    getPersonDetails: function getPersonDetails(xml, func, multipleDepartments) {

        var nameNode = $(xml).find('name');
        var photoNode = $(xml).find('photo');
        var addressNode = $(xml).find('address');
        var webNode = $(xml).find('web');
        var phoneNode = $(xml).find('phone');
        var departmentNode = $(xml).find('department');

        var firstName = nameNode.attr('firstName');
        var lastName = nameNode.attr('lastName');
        var auid = nameNode.attr('employeeId');
        var photoSrc = photoNode.attr('external');
        var centerMap, street, zip, city, address;

        // Vi løber alle tilknyttede enheder igennem og finder ud af, om adresserne er forskellige
        var found_addresses = [];
        for (var j = 0; j < departmentNode.length; j++) {
            street = $(addressNode[j]).attr('street');
            zip = $(addressNode[j]).attr('zip');
            city = $(addressNode[j]).attr('city');
            address = street + ", " + zip + " " + city;
            var found = false;
            for (var k = 0; k < found_addresses.length; k++) {
                if (found_addresses[k] === address) {
                    found = true;
                }
            }
            if (j === 0 || !found) {
                found_addresses.push(address);
            }
        }

        var email;
        var officePhone;
        var buildingNumber;
        var personHTML = '<div class="vcard">';
        // Vi løber alle enheder igennem
        for (var i = 0; i < departmentNode.length; i++) {
            street = $(addressNode[i]).attr('street');
            zip = $(addressNode[i]).attr('zip');
            city = $(addressNode[i]).attr('city');
            buildingNumber = $(addressNode[i]).attr('building');
            var room = $(addressNode[i]).attr('room');
            officePhone = $(phoneNode[i]).attr('office');
            var mobilePhone = $(phoneNode[i]).attr('mobile');
            email = $(webNode[i]).attr('email');

            personHTML += '<h2 class="fn"><a class="url" href="http://au.dk/' + email + '">' + firstName + ' ' + lastName + '</a></h2>';
            personHTML += '<p>';
            if (typeof photoSrc !== 'undefined') {
                personHTML += '<a class="photo" href="http://au.dk/' + email + '"><img style="width: 70px; float: right; margin-left: 1em;" src="' + this.source.photos + photoSrc.replace('../../../', '').replace('.JPG', '.jpg') + '" alt="" title="" /></a>';
            }

            if (found_addresses.length === 1) {
                for (var n = 0; n < departmentNode.length; n++) {
                    personHTML += '<span class="org">' + $(departmentNode[n]).attr('name') + '</span><br />';
                }
                centerMap = true;
            } else {
                personHTML += '<span class="org">' + $(departmentNode[i]).attr('name') + '</span><br />';
                centerMap = false;
            }

            if (typeof buildingNumber !== 'undefined') {
                personHTML += (AU.pageLang === "da" ? "Bygning " : "Building ") + buildingNumber;
                if (typeof room !== 'undefined') {
                    personHTML += ", " + (AU.pageLang === "da" ? "Kontor " : "Office ") + room + "<br />";
                } else {
                    personHTML += "";
                }
            }

            personHTML += '<span class="adr"><span class="street-address">' + street + '</span>, <span class="postal-code">' + zip + '</span> <span class="locality">' + city + '</span></span><br /><br />';

            if (typeof officePhone !== 'undefined') {
                if (AU.helpers.isMobileDevice()) {
                    personHTML += 'T: <a class="tel" href="tel:' + officePhone + '">' + officePhone + '</a><br />';
                } else {
                    personHTML += 'T: <span class="tel">' + officePhone + '</span><br />';
                }
            }

            if (typeof mobilePhone !== 'undefined') {
                if (AU.helpers.isMobileDevice()) {
                    personHTML += 'M: <a class="tel" href="tel:' + mobilePhone + '">' + mobilePhone + '</a><br />';
                } else {
                    personHTML += 'M: <span class="tel">' + mobilePhone + '</span><br />';
                }
            }

            if (typeof email !== 'undefined') {
                personHTML += 'E: <a class="email" href="mailto:' + email + '">' + email + '</a><br />';
            }

            /*
            if (typeof auid !== 'undefined') {
                personHTML += 'ID: <span class="auid">AU' + auid + '</span>';
            }
            */

            personHTML += '</p>';

            if (i === 0 && !multipleDepartments) {
                break;
            }
        }

        personHTML += '</div>';

        if (typeof func === 'function') {
            func(personHTML, buildingNumber, email, officePhone, address, auid, centerMap);
        }
    },
    getPeople: function getPeople(xml) {
        var people = [];
        var peopleXML = $(xml).find('person');

        $.each(peopleXML, function (i, p) {
            var name = $(p).find('name');
            var room = name.attr('room');
            var lastname = name.attr('lastName');
            var firstname = name.attr('firstName');
            var details = $(p).find('details');
            var detailsSource = details.attr('external');

            var person = {
                id: i,
                lastname: lastname,
                firstname: firstname,
                room: room,
                detailsSource: detailsSource,
                label: firstname + ' ' + lastname,
                value: firstname + ' ' + lastname,
                url: detailsSource
            };

            people[i] = person;
        });

        return people;
    }
};
'use strict';

/**
 * Titel: DESIGN
 * Beskrivelse: metoder som udbedrer en række "shortcomings" i designet eller tilføjer ekstra features til elementer
 * Status: er en blanding af nyt og gammelt. ES5-syntax hele vejen igennem
 */

AU.design = {
    setFocusFieldIds: function setFocusFieldIds() {
        jQuery('.au_focus').each(function (i) {
            if (!jQuery(this).attr('id')) {
                jQuery(this).attr('id', 'focusid_' + i);
            }
        });
    },
    setBuildingLinks: function setBuildingLinks(elementToSearchSelector) {
        var elementToSearch = jQuery(elementToSearchSelector);

        if (elementToSearch.length > 0) {
            var mapUrl = AU.pageLang === "da" ? '//www.au.dk/kort/' : '//www.au.dk/en/map/';
            var buildingPhrase = AU.pageLang === "da" ? 'bygning' : 'building';
            var re = new RegExp('(' + buildingPhrase + ' (\\d{4}))', 'ig');
            jQuery.each(elementToSearch, function () {
                jQuery(this).html(jQuery(this).html().replace(re, '<a href="' + mapUrl + '?b=$2">$1</a>'));
            });
        }
    },
    onCollapseOpen: [],
    onCollapseClose: [],
    setCollapse: function setCollapse() {

        var openEvent = document.createEvent('Event');
        openEvent.initEvent('collapsible-open', true, true);

        var closeEvent = document.createEvent('Event');
        closeEvent.initEvent('collapsible-close', true, true);

        var collapsibles = jQuery('.csc-frame.au_collapsible');
        collapsibles.each(function () {
            var me = jQuery(this);
            var domElement = me.get(0);
            var header = jQuery('> .csc-header', me);

            header.unbind('click').on('click', function () {
                me.toggleClass('au_collapsed');
                if (me.hasClass('au_collapsed')) {
                    domElement.dispatchEvent(closeEvent);
                } else {
                    domElement.dispatchEvent(openEvent);
                }
            });

            var d = AU.design;
            domElement.addEventListener('collapsible-open', function (e) {
                for (var i = 0; i < d.onCollapseOpen.length; i++) {
                    if (typeof d.onCollapseOpen[i] === 'function') {
                        d.onCollapseOpen[i](e.target);
                    }
                }
            }, false);

            domElement.addEventListener('collapsible-close', function (e) {
                for (var i = 0; i < d.onCollapseClose.length; i++) {
                    if (typeof d.onCollapseClose[i] === 'function') {
                        d.onCollapseClose[i](e.target);
                    }
                }
            }, false);
        });

        this.openCollapsibleFromHash(location.hash);

        jQuery(window).on('hashchange', function () {
            AU.design.openCollapsibleFromHash(location.hash);
        });
    },
    openCollapsibleFromHash: function openCollapsibleFromHash(hash) {
        if (hash.indexOf('c') === 1) {
            jQuery(hash + '.csc-frame.au_collapsible, ' + hash + ' > .csc-frame.au_collapsible, ' + hash + ' > .csc-frame.au_collapsible').removeClass('au_collapsed');
            jQuery(hash).parents('.au_collapsible').removeClass('au_collapsed');
            document.querySelector(hash).scrollIntoView();
        }
    },
    grayscale: function grayscale(src) {
        try {
            var supportsCanvas = !!document.createElement('canvas').getContext;
            if (supportsCanvas) {
                var canvas = document.createElement('canvas'),
                    context = canvas.getContext('2d'),
                    imageData,
                    px,
                    length,
                    i = 0,
                    gray,
                    img = new Image();

                img.src = src;
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);

                imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                px = imageData.data;
                length = px.length;

                for (; i < length; i += 4) {
                    gray = px[i] * 0.3 + px[i + 1] * 0.59 + px[i + 2] * 0.11;
                    px[i] = px[i + 1] = px[i + 2] = gray;
                }

                context.putImageData(imageData, 0, 0);
                return canvas.toDataURL();
            } else {
                return src;
            }
        } catch (err) {
            console.log(err);
            return src;
        }
    },
    setBannerElementsReady: function setBannerElementsReady() {
        jQuery('.aubanner a').hover(function () {
            jQuery(this).find('.gotcolors').stop().animate({ opacity: 1 }, 200);
        }, function () {
            jQuery(this).find('.gotcolors').stop().animate({ opacity: 0 }, 500);
        });
    },
    setBannerElementsLoad: function setBannerElementsLoad() {
        jQuery('.aubanner:not(.aubanner-2) img').each(function () {
            // Fordi det er fuldstændig utænkeligt, at vi kan få fjernet Prototype fra pure.au.dk...
            var bc = jQuery('.aubanner-content', jQuery(this).parents('.aubanner'));
            bc.css('background-image', "url('" + AU.design.grayscale(this.src) + "')");
        }).animate({ opacity: 1 }, 500);
    },
    setHero: function setHero() {
        var hero = jQuery('.hero');
        if (hero.length > 0) {
            jQuery(window).scroll(function () {
                var x = jQuery(this).scrollTop() + (jQuery('body').hasClass('layout13') ? 0 : -200);
                hero.css('background-position-y', parseInt(x / 10) + 'px');
            });
        }
    },
    setMagnificPopup: function setMagnificPopup() {
        var lightbox = jQuery('.csc-textpic a[rel*="lightbox"], .image a[rel*="lightbox"]');

        // http://stackoverflow.com/questions/6900958/using-jquery-to-perform-a-function-on-elements-with-unique-rel-attribute
        var rels = {};
        lightbox.each(function () {
            var r = jQuery(this).attr('rel');
            rels[r] = 1;
        });

        rels = Object.keys(rels);
        jQuery.each(rels, function (index, value) {
            jQuery('a[rel="' + value + '"]').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                },
                modal: false
            });
        });

        var newsImgs = jQuery('.au_news-single-sub-images a, .au_news-single-img a, .news-set1-img1all a');
        newsImgs.magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            },
            modal: false
        });
    },
    setTopWarning: function setTopWarning() {
        if (window.topWarning) {
            jQuery('body').prepend(jQuery('<div />', {
                'class': 'ie-warning',
                'html': AU.pageLang == 'da' ? window.topWarningTextDa : window.topWarningTextEn
            }));
        }
    }
};
'use strict';

/**
 * Titel: SOCIAL
 * Beskrivelse: #SoME-deling
 * Status: er kun i nogen grad omskrevet fra 2011/2015-udgaven. Kunne med fordel ændres til ES6/React (hvis behovet er der?)
 */

AU.social = {
    options: {
        config: {
            mode: 'sharing',
            container: '.au_social_sharebox',
            tracking: '/virtual/social/sharing/',
            links: [{
                classname: 'facebook',
                text: 'AU på Facebook',
                url: 'https://www.facebook.com/UniAarhus'
            }, {
                classname: 'twitter',
                text: 'AU på Twitter',
                url: 'https://twitter.com/AarhusUni'
            }, {
                classname: 'linkedin',
                text: 'AU på LinkedIn',
                url: 'http://www.linkedin.com/company/aarhus-university-denmark-?trk=hb_tab_compy_id_4648'

            }, {
                classname: 'youtube',
                text: 'AU på YouTube',
                url: 'http://www.youtube.com/user/AarhusUniversity'

            }, {
                classname: 'vimeo',
                text: 'AU på Vimeo',
                url: 'http://vimeo.com/channels/181624'
            }, {
                classname: 'instagram',
                text: 'AU på Instagram',
                url: 'http://instagram.com/aarhusuni'
            }],
            sharing: [{
                classname: 'facebook',
                text: 'Del på Facebook'
            }, {
                classname: 'twitter',
                text: 'Del på Twitter'
            }, {
                classname: 'linkedin',
                text: 'Del på LinkedIn'
            }, {
                classname: 'email',
                text: 'Send til en ven'
            }],
            direction: '',
            icons: true,
            spacing: false,
            title: '',
            url: '',
            size: 'medium',
            color: 'real',
            initFunc: null
        }
    },
    sharers: [],
    newsSharer: {
        config: {
            container: '#au_content .au_news-single-item',
            tracking: '/virtual/ttnews/sharing/',
            direction: 'horizontal'
        }
    },
    peopleXsSharer: {
        config: {
            container: '#au_content .peoplexs-single',
            tracking: '/virtual/peoplexs/sharing/',
            direction: 'horizontal'
        }
    },
    mergeOptions: function mergeOptions(sharer) {
        if (!sharer.hasOwnProperty('config')) {
            sharer.config = {};
        }

        for (var conf in this.options.config) {
            if (!sharer.config.hasOwnProperty(conf)) {
                sharer.config[conf] = this.options.config[conf];
            }
        }
    },
    renderButtons: function renderButtons(sharer) {

        var html = "";
        if (sharer.config.mode === 'sharing') {

            var shareTitle = sharer.config.title;
            var shareUrl = sharer.config.url;

            var direction = sharer.config.direction;
            var icons = sharer.config.icons;
            var spacing = sharer.config.spacing;
            var services = sharer.config.sharing;

            var dirClass = direction === '' ? '' : ' ' + direction;
            var iconClass = icons ? ' icons' : '';
            var spacedClass = spacing ? ' spacing' : '';

            html += '<ul class="resetlist au_social_sharing' + dirClass + iconClass + spacedClass + ' services-' + services.length + '">';
            for (var i = 0; i < services.length; i++) {
                var append = services[i].classname === 'email' ? '<ul class="resetlist"><li class="outlook"><a title="' + (AU.pageLang === 'da' ? 'Send via e-mailklienten på din computer' : 'Send via the email client on your computer') + '" href="mailto:?subject=' + shareTitle + '&body=' + shareUrl + '">' + (AU.pageLang === "da" ? 'E-mail' : 'Email') + '</a></li><li class="gmail"><a title="Send via Gmail" target="_blank" href="http://www.addtoany.com/add_to/google_gmail?linkurl=' + shareUrl + '">Gmail</a></li><li class="yahoo"><a title="Send via Yahoo Mail" target="_blank" href="http://www.addtoany.com/add_to/yahoo_mail?linkurl=' + shareUrl + '">Yahoo Mail</a></li><li class="outlookcom"><a title="Send via outlook.com" target="_blank" href="http://www.addtoany.com/add_to/outlook_com?linkurl=' + shareUrl + '">Outlook.com</a></li></ul>' : "";
                html += '<li class="' + services[i].classname + '"><a href="' + shareUrl + '" data-title="' + shareTitle + '" data-url="' + shareUrl + '">' + services[i].text + '</a>' + append + '</li>';
            }
            html += '</ul>';
        } else {

            var links = sharer.config.links;
            var color = sharer.config.color === 'real' ? '' : ' style="color: ' + sharer.config.color + ';"';

            html += '<ul class="resetlist au_social_linking ' + sharer.config.size + '">';
            for (var j = 0; j < links.length; j++) {
                html += '<li class="' + links[j].classname + '"><a' + color + ' title="' + links[j].text + '" href="' + links[j].url + '"></a></li>';
            }
            html += '</ul>';
        }

        sharer.container.append(html);
    },
    disableAddThisForOldNews: function disableAddThisForOldNews() {
        $('.au_news-single-item .addthis_toolbox').hide();
    },
    trackingFunc: function trackingFunc(sharer) {
        $('ul.au_social_sharing a', sharer.container).on('click', function () {
            // Used to track old Google Analytics
        });
    },
    init: function init() {

        if (this.sharers.length > 0) {

            var c = this.options.config;
            if (AU.pageLang !== 'da') {
                c.links[0].text = 'AU on Facebook';
                c.links[1].text = 'AU on Twitter';
                c.links[2].text = 'AU on LinkedIn';
                c.links[3].text = 'AU on YouTube';
                c.links[4].text = 'AU on Vimeo';
                c.links[5].text = 'AU on Instagram';
                c.sharing[0].text = 'Facebook share';
                c.sharing[1].text = 'Twitter share';
                c.sharing[2].text = 'LinkedIn share';
                c.sharing[3].text = 'Send to a friend';
            }

            c.title = $('title').text();
            c.url = location.href;
            c.initFunc = function (box) {
                if (box.config.mode === 'sharing') {
                    var s = AU.social;
                    s.fb.setClick(box);
                    s.twitter.setClick(box);
                    s.linkedin.setClick(box);
                    s.mail.setClick(box);
                }
            };

            for (var i = 0; i < this.sharers.length; i++) {
                var s = this.sharers[i];

                if (!s.loaded) {
                    this.mergeOptions(s);
                    s.container = $(s.config.container).eq(0);
                    this.renderButtons(s);
                    s.loaded = true;
                    s.config.initFunc(s);

                    this.trackingFunc(s);
                }
            }
        }
    },
    fb: {
        openShareDialog: function openShareDialog(url) {
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, '', 'width=600,height=450');
        },
        setClick: function setClick(sharer) {
            $('ul.au_social_sharing .facebook a', sharer.container).on('click', function (e) {
                e.preventDefault();
                var shareUrl = $(this).data('url');
                AU.social.fb.openShareDialog(shareUrl);
            });
        }
    },
    twitter: {
        openShareDialog: function openShareDialog(title, url) {
            url = encodeURI(url);
            title = encodeURI(title);
            window.open('https://twitter.com/intent/tweet?text=' + title + '&url=' + url, '', 'width=550,height=450');
        },
        setClick: function setClick(sharer) {
            $('ul.au_social_sharing .twitter a', sharer.container).on('click', function (e) {
                e.preventDefault();
                var shareTitle = $(this).data('title');
                var shareUrl = $(this).data('url');
                AU.social.twitter.openShareDialog(shareTitle, shareUrl);
            });
        }
    },
    linkedin: {
        openShareDialog: function openShareDialog(url) {
            url = encodeURI(url);
            window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + url, '', 'width=850,height=550');
        },
        setClick: function setClick(sharer) {
            $('ul.au_social_sharing .linkedin a', sharer.container).on('click', function (e) {
                e.preventDefault();
                var shareUrl = $(this).data('url');
                AU.social.linkedin.openShareDialog(shareUrl);
            });
        }
    },
    mail: {
        setClick: function setClick(sharer) {
            $('ul.au_social_sharing .email > a', sharer.container).on('click', function (e) {
                e.preventDefault();
                $('> ul', $(this).parent()).toggle();
                $(this).toggleClass('active');
            });
        }
    }
};

AU.social.instashow = {
    loaded: false,
    load: function load(callback) {
        if (typeof callback === 'function') {
            if (callback()) {
                $.getScript('https://apps.elfsight.com/p/platform.js');
                AU.social.instashow.loaded = true;
            }
        }
    }
};

AU.social.yottie = {
    loaded: false,
    load: function load(callback) {
        if (typeof callback === 'function') {
            if (callback()) {
                $.getScript('https://cdn.au.dk/vendor/js/jquery.yottie.bundled.js');
                AU.social.yottie.loaded = true;
            }
        }
    }
};
'use strict';

/**
 * Titel: DATABOX
 * Beskrivelse: dynamiske søgebokse, som er strøet ud med lind hånd over hele websitet
 * Status: er fuldstændig omskrevet til ES6/React og indlæses nu via components-biblioteket for at holde React ude af dette projekt
 */

$.ajaxSetup({
    cache: true
});

AU.databox = {
    boxes: [],
    scriptLoaded: false,
    loadExternal: function loadExternal() {
        var db = new AUDatabox(AU.databox.boxes);
        db.init();
    },
    init: function init() {
        if (this.boxes.length > 0) {
            if (!this.scriptLoaded) {
                var componentsUrl = AU.cdn + '/components/umd/databox.js?v=' + AU.version;
                jQuery.getScript(componentsUrl, function () {
                    AU.databox.loadExternal();
                    AU.databox.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
};
'use strict';

/**
 * Titel: FLOWBOX
 * Beskrivelse: bruges primært til visning af kontaktpersoner på medarbejdersider, samt til åbningstider for it-supporten
 * Status: er fuldstændig omskrevet til ES6/React og indlæses nu via components-biblioteket for at holde React ude af dette projekt
 */

AU.flowbox = {
    boxes: [],
    scriptLoaded: false,
    loadExternal: function loadExternal() {
        var fb = new AUFlowbox(AU.pageLang, AU.flowbox.boxes);
        fb.init();
    },
    init: function init() {
        if (this.boxes.length > 0) {
            if (!this.scriptLoaded) {
                var componentsUrl = AU.cdn + '/components/umd/flowbox.js?v=' + AU.version;
                jQuery.getScript(componentsUrl, function () {
                    AU.flowbox.loadExternal();
                    AU.flowbox.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
};
'use strict';

/**
 * Titel: ALPHABOX
 * Beskrivelse: benyttes fx til medarbejderserviceboks
 * Status: er fuldstændig omskrevet til ES6/React og indlæses nu via components-biblioteket for at holde React ude af dette projekt
 */

$.ajaxSetup({
    cache: true
});

AU.alphabox = {
    boxes: [],
    scriptLoaded: false,
    loadExternal: function loadExternal() {
        var ab = new AUAlphabox(AU.alphabox.boxes);
        ab.init();
    },
    init: function init() {
        if (this.boxes.length > 0) {
            if (!this.scriptLoaded) {
                var componentsUrl = AU.cdn + '/components/umd/alphabox.js?v=' + AU.version;
                jQuery.getScript(componentsUrl, function () {
                    AU.alphabox.loadExternal();
                    AU.alphabox.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
};
'use strict';

/**
 * Titel: FALLBACK
 * Beskrivelse: består af den ældgamle 'pingAnalytics' til GA-tracking og nogle ukendte/LF-udviklede funktioner, der kan ske at være i brug rundt omkring...
 * Status: Do. Not. Touch.
 */

function pressed(url) {
    location.href = url;
}

function pres(url) {
    location.href = url;
}

function replaceall(s, x, y) {
    if (x.length > 0) {
        var found = s.indexOf(x);
        while (found >= 0) {
            s = s.substring(0, found) + y + s.substring(found + x.length, s.length);
            found = s.indexOf(x, found + y.length);
        }
    }
    return s;
}

function multipressed(xx, urlPattern, StartValue) {
    var url = urlPattern;
    var allow = StartValue;
    var OK = true;
    for (var i = 0; i < xx.elements.length; i++) {
        if (xx.elements[i].type === 'select-one') {
            if (OK) {
                OK = xx.elements[i].selectedIndex >= allow;
            }
            url = replaceall(url, '$' + (i + 1), xx.elements[i].options[xx.elements[i].selectedIndex].value);
            url = replaceall(url, '¤' + (i + 1), xx.elements[i].options[xx.elements[i].selectedIndex].value);
        }
    }
    url = url.toLowerCase();
    if (OK) {
        location.href = url;
    } else {
        window.alert('Vælg en værdi i alle felter!');
    }
}

// En kommentar
'use strict';

/**
 * Titel: RESPONSIVE
 * Beskrivelse: indeholder efterhånden primært logik til omsættelse af tabeller til responsiv opførsel (lister), herunder studiediagrammerne. Derudover er der en lille smule logik til det nye design
 * Status: er kun i nogen grad omskrevet fra 2011/2015-udgaven
 */

AU.responsive = {
    state: null,
    stateHistory: [],
    modes: {
        PHONE: {
            ID: 0,
            NAME: 'PHONE',
            MAX: 640
        },
        TABLET: {
            ID: 1,
            NAME: 'TABLET',
            MAX: 1024
        },
        DESKTOP: {
            ID: 2,
            NAME: 'DESKTOP'
        }
    },
    detectDevice: function detectDevice() {

        var state = null;
        var modes = AU.responsive.modes;
        if (Foundation.MediaQuery.current === 'small') {
            state = modes.PHONE;
        } else if (Foundation.MediaQuery.current === 'medium') {
            state = modes.TABLET;
        } else {
            state = modes.DESKTOP;
        }

        if (state !== AU.responsive.state) {
            AU.responsive.state = state;
            AU.responsive.stateHistory.push(state.NAME);
            console.log(state.NAME);
            AU.responsive.setMarkup();
        }
    },
    markupRenderFunctions: [],
    runMarkupFunctions: function runMarkupFunctions() {
        for (var i = 0; i < this.markupRenderFunctions.length; i++) {
            if (typeof this.markupRenderFunctions[i] === 'function') {
                this.markupRenderFunctions[i]();
            }
        }
    },
    setMarkup: function setMarkup() {
        var state = AU.responsive.state;

        var utilityLinks = jQuery('.utility-links');
        var headerMenu = jQuery('.header-menu');
        var sectionHeader = jQuery('.section-header .logo');

        var pageNav = jQuery('#au_section_nav_inner_list');
        var titleBar = jQuery('.title-bar');
        var menuTitle = jQuery('.menu-title');
        var leftMenu = jQuery('.left-menu');
        var tablesScroll = jQuery('table.au_responsive_table_scroll');

        if (state.NAME === 'PHONE') {

            headerMenu.append(utilityLinks);
            if (jQuery('body').hasClass('universe')) {
                headerMenu.after(pageNav);
                pageNav.wrap('<div class="large-12 medium-12 small-12 columns" />');
            } else {
                utilityLinks.before(pageNav);
            }
            titleBar.before(menuTitle);
            pageNav.addClass('responsive-pagenav');

            tablesScroll.each(function () {
                var table = jQuery(this);
                table.wrap('<div class="responsive-table-container" />');
            });
        } else {

            var wasPhone = function wasPhone() {
                var states = AU.responsive.stateHistory.length;
                return states > 1 ? AU.responsive.stateHistory[states - 2] === 'PHONE' : false;
            };

            if (wasPhone()) {
                sectionHeader.append(utilityLinks);
                leftMenu.append(pageNav);
                pageNav.before(menuTitle);
            }

            tablesScroll.each(function () {
                var table = jQuery(this);
                if (table.parent().is('div.responsive-table-container')) {
                    table.unwrap();
                }
            });
        }

        this.runMarkupFunctions();
    }
};

AU.edutables = {
    tables: [],
    scriptLoaded: false,
    loadExternal: function loadExternal() {
        var dg = new AUDiagramme(AU.pageLang, AU.edutables.tables);
        dg.init();
    },
    init: function init() {
        if (this.tables.length > 0) {
            if (!this.scriptLoaded) {
                var componentsUrl = AU.cdn + '/components/umd/diagramme.js';
                jQuery.getScript(componentsUrl, function () {
                    AU.edutables.loadExternal();
                    AU.edutables.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
};

AU.addReadyFunction(function () {
    AU.responsive.detectDevice();
    AU.edutables.init();
});

AU.addResizeFunction(function () {
    AU.responsive.detectDevice();
});
'use strict';

/**
 * Titel: NEWS
 * Beskrivelse: de 'nye nyheder', som indlæses asynkront og åbnes (nogle gange) i overlay
 * Status: er fuldstændig omskrevet til ES6/React og indlæses nu via components-biblioteket for at holde React ude af dette projekt
 */

var disqus_no_style = true;
var disqus_shortname = 'aukom';
var disqus_url = '';
var disqus_loaded = false;

$.ajaxSetup({
    cache: true
});

AU.news = {
    feeds: [],
    scriptLoaded: false,
    loadExternal: function loadExternal() {
        this.setPopup();
        var news = new AUNews(AU.pageLang, AU.news.feeds);
        news.init();
        new AUNewsCollage(AU.pageLang, document.querySelector('#featured-content'));
    },
    setPopup: function setPopup() {
        var body = document.querySelector('body');
        var popup = document.createElement('div');
        popup.setAttribute('id', 'au_news_popup');
        body.appendChild(popup);
    },
    init: function init() {
        var fc = document.querySelector('#featured-content');
        if ((this.feeds && this.feeds.length) > 0 || fc) {
            if (!this.scriptLoaded) {
                var componentsUrl = (typeof window.componentsUri !== 'undefined' ? window.componentsUri : AU.cdn) + '/components/umd/news.js?v=' + AU.version;
                jQuery.getScript(componentsUrl, function () {
                    AU.news.loadExternal();
                    AU.news.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
};

String.prototype.hashCode = function () {
    var hash = 0,
        i,
        ch,
        l;
    if (this.length === 0) {
        return '_' + Math.abs(hash);
    }
    for (i = 0, l = this.length; i < l; i++) {
        ch = this.charCodeAt(i);
        hash = (hash << 5) - hash + ch;
        hash |= 0; // Convert to 32bit integer
    }
    return '_' + Math.abs(hash);
};

//http://stackoverflow.com/questions/9235304/how-to-replace-the-location-hash-and-only-keep-the-last-history-entry
(function (namespace) {
    if ('replaceState' in history) {
        namespace.replaceHash = function (newhash) {
            if (('' + newhash).charAt(0) !== '#') {
                newhash = '#' + newhash;
            }
            history.replaceState('', '', newhash);
        };
    } else {
        var hash = location.hash;
        namespace.replaceHash = function (newhash) {
            if (location.hash !== hash) {
                history.back();
            }
            location.hash = newhash;
        };
    }
})(window);
'use strict';

/**
 * Titel: MAPS
 * Beskrivelse: Google Maps-baseret kortløsning.
 * Status: er noget af det ældste kode, vi har kørende. Virker som sådan fint nok, men ved et større kortprojekt ville det være oplagt at få omskrevet
 */

/*
Example: The following TypoScript demonstrates how to load the Google Maps script asynchronously and how to load additional script(s) when the googleMapsScriptLoadedEvent is fired and the Google Maps script is fully loaded.

page {
    jsInline {
        10 = TEXT
        10.value (
            # Load the Google Maps script asynchronously
            AU.maps.loadGoogleMapsScript();

            # Example: Load some script(s) when the googleMapsScriptLoadedEvent is fired and the Google Maps script is fully loaded
            window.addEventListener('googleMapsScriptLoadedEvent', function() {
                var script = document.createElement('script');
                script.src = 'https://test.com/my-script.js';
                script.type = 'text/javascript';
                document.body.appendChild(script);
            });
        )
    }
}
*/

var GOOGLE_MAPS_API_KEY = "AIzaSyBP04DSgKVcqkYGO_Xb4GgqXHMzRO_2jzo";

AU.maps = {
    loadGoogleMapsScript: function loadGoogleMapsScript() {
        var async = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        // Check if the Google Maps script is already loaded
        if (typeof google !== 'undefined' && google.maps) {
            AU.maps.googleMapsScriptLoaded = true;
            return;
        }

        // Construct the base URL with the API key
        var scriptUrl = 'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_MAPS_API_KEY;

        // Conditionally add the async loading parameter to the URL
        if (async) {
            scriptUrl += '&loading=async';
        }

        scriptUrl += '&language=' + AU.pageLang;
        scriptUrl += '&callback=AU.maps.googleMapsScriptCallback';

        // Create a script element and set the constructed URL
        var script = document.createElement('script');
        script.src = scriptUrl;

        // Handle the script load success
        script.onload = function () {
            console.log("AU.maps: Google Maps script has been loaded. Now waiting for the callback to initialize.");
        };

        // Handle script load failure and capture details
        script.onerror = function (event) {
            console.error("AU.maps: Error loading Google Maps script:", event);
            console.error("AU.maps: Script URL:", event.target.src); // URL of the failed script
        };

        // Append the script to the document's head to initiate the load
        document.head.appendChild(script);
    },
    // If the Google maps script is loaded, we use this callback to dispatch an googleMapsScriptLoadedEvent event
    googleMapsScriptLoaded: false,
    googleMapsScriptCallback: function googleMapsScriptCallback() {
        AU.maps.googleMapsScriptLoaded = true;
        console.log("AU.maps: Google Maps script callback has been executed. Google Maps is ready for use.");

        // Dispatch a custom event to notify that Google Maps is ready
        var event = new CustomEvent('googleMapsScriptLoadedEvent');
        window.dispatchEvent(event);
    },
    create: function create(container, width, height, zoomLevel, center, scrollWheel, draggable, clickable, streetView, pco, zco, svco) {

        var mapOptions = {
            zoom: zoomLevel,
            center: center,
            scrollwheel: AU.helpers.isMobileDevice() || AU.responsive.state.NAME == 'PHONE' ? false : scrollWheel,
            draggable: draggable,
            clickable: clickable,
            streetViewControl: streetView,
            mapTypeControlOptions: {
                mapTypeIds: ['default', google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            panControlOptions: !pco ? {} : pco,
            zoomControlOptions: !zco ? {} : zco,
            streetViewControlOptions: !svco ? {} : svco
        };

        // Opretter kort
        var map = new google.maps.Map(container, mapOptions);

        // Kortets størrelse sættes
        container.style.width = !isNaN(width) ? width + 'px' : width;
        container.style.height = !isNaN(height) ? height + 'px' : height;

        return map;
    },
    setBounds: function setBounds(map, markers) {
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
            for (var j = 0; j < markers[i].length; j++) {
                bounds.extend(markers[i][j].position);
            }
        }
        map.fitBounds(bounds);
    },
    marker: {
        add: function add(map, point, markerIcon, markerDraggable, markerClickable, markerPanTo, markerClickZoom, markerClickZoomLevel, infoWindow, windowContent, title, id) {
            var marker = new google.maps.Marker({
                position: point,
                map: map,
                icon: markerIcon,
                draggable: markerDraggable,
                title: title
            });

            if (typeof id !== 'undefined') {
                marker.set('id', id);
            }

            // Listeners tilføjes markør
            google.maps.event.addListener(marker, 'click', function () {
                if (windowContent !== '<p><strong></strong></p>') {
                    // Infowindow åbner ved klik
                    infoWindow.open(map, this);
                    infoWindow.setContent(windowContent);
                }

                if (markerClickable) {
                    if (markerPanTo) {
                        map.panTo(this.getPosition());
                    }

                    if (!markerClickZoom || map.getZoom() > markerClickZoomLevel) {
                        map.setZoom(map.getZoom());
                    } else {
                        map.setZoom(markerClickZoomLevel);
                    }
                }
            });

            // At flytte en markør betragtes som et klik på kortet (det giver tilsyneladende ingen problemer, skulle der ikke være defineret click-event på kortet)
            if (markerDraggable) {
                google.maps.event.addListener(marker, 'dragend', function (point) {
                    google.maps.event.trigger(map, 'click', point);
                });
            }

            return marker;
        }
    },
    route: {
        destinationFrom: false,
        destinationTo: false,
        directionsDisplay: null,
        directionsService: null,
        calc: function calc(map, from, to, travelMode, directionsService, directionsDisplay, waypointLocations) {
            if (from !== '') {
                var waypoints = [];
                if (typeof waypointLocations !== 'undefined') {
                    for (var i = 0; i < waypointLocations.length; i++) {
                        waypoints.push({
                            location: waypointLocations[i],
                            stopover: false
                        });
                    }
                }

                var request = {
                    origin: from,
                    destination: to,
                    travelMode: travelMode,
                    waypoints: waypoints
                };

                directionsService.route(request, function (response, status) {
                    if (status === google.maps.DirectionsStatus.OK) {
                        if (directionsDisplay.getMap() === null) {
                            directionsDisplay.setMap(map);
                        }
                        directionsDisplay.setDirections(response);
                    } else {
                        window.alert(status);
                    }
                });
            }
        },
        createDirectionObjects: function createDirectionObjects(map, container) {
            var rendererOptions = {
                draggable: true
            };

            var directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
            var panel = container;

            directionsDisplay.setMap(map);
            directionsDisplay.setPanel(panel);

            return directionsDisplay;
        }
    },
    geocodePersonAddress: function geocodePersonAddress(map, options, icon, address, centerMap, infoWindow, windowContent, func) {
        AU.maps.geocoder.geocode({
            'address': address
        }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var point = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                var marker = AU.maps.marker.add(map, point, icon, options.markerDrag, options.markerClick, options.clickPanTo, options.clickZoom, options.clickZoomLevel, infoWindow, windowContent, '');

                if (typeof func === 'function') {
                    func(marker, point, centerMap, infoWindow, windowContent);
                }
            }
        });
    },
    helpers: {
        getDistanceBetweenPoints: function getDistanceBetweenPoints(point1, point2) {
            return google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
        },
        convertFromLatLng: function convertFromLatLng(coord) {
            return Math.round(coord * 1000000);
        },
        convertToLatLng: function convertToLatLng(coord) {
            var c;
            if (coord.length === 7) {
                c = coord.substring(0, 1) + '.' + coord.substring(1);
            }
            if (coord.length === 8) {
                c = coord.substring(0, 2) + '.' + coord.substring(2);
            }
            if (coord.length === 9) {
                c = coord.substring(0, 3) + '.' + coord.substring(3);
            }
            return c;
        },
        getCurrentDate: function getCurrentDate() {
            var d = new Date();
            var date = d.getDate();
            var month = d.getMonth() + 1;
            var currentDate = (date < 10 ? '0' + date : date) + '.' + (month < 10 ? '0' + month : month) + '.' + ('' + d.getFullYear()).substring(2);
            return currentDate;
        },
        getCurrentTime: function getCurrentTime() {
            var d = new Date();
            var hours = d.getHours();
            var minutes = d.getMinutes();
            var currentTime = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
            return currentTime;
        },
        sortSelect: function sortSelect(element) {
            $(element).each(function () {
                var selectedValue = $(this).val();
                $(this).html($('option', $(this)).sort(function (a, b) {
                    return a.text === b.text ? 0 : a.text < b.text ? -1 : 1;
                }));
                $(this).val(selectedValue);
            });
        },
        sortAlphaObj: function sortAlphaObj(a, b) {
            if (typeof a.name !== 'undefined' && typeof b.name !== 'undefined') {
                return a.name > b.name ? 1 : -1;
            }
            return 0;
        }
    },
    infoWindow: null,
    geocoder: null
};

// Slut på det generelle ("biblioteket") – nedenstående omhandler de små kort, som (primært) sidder på institutsiderne

var maps = [];

AU.maps.single = {
    options: {
        image: '',
        markerDrag: !window._markerDrag ? false : window._markerDrag,
        markerClick: !window._markerClick ? true : window._markerClick,
        clickPanTo: !window._clickPanTo ? false : window._clickPanTo,
        clickZoom: !window._clickZoom ? false : window._clickZoom,
        clickZoomLevel: window._clickZoomLevel || 12,
        defaultZoomLevel: window._defaultZoomLevel || 16,
        scrollWheel: !window._scrollWheel ? false : window._scrollWheel,
        draggable: !window._draggable ? true : window._draggable,
        clickable: !window._clickable ? true : window._clickable,
        streetView: !window._streetView ? false : window._streetView,
        centerLat: window._centerLat || 56.20,
        centerLng: window._centerLng || 10.50,
        mapWidth: '99.5%',
        mapHeight: 275
    },
    center: null,
    maps: window.maps,
    mapDivs: [],
    firstMapLoaded: false,
    findFirstMap: function findFirstMap() {
        var i = 1;
        var firstMap;
        do {
            firstMap = document.getElementById('au_map' + (i === 1 ? '' : i));
            i++;
        } while (!firstMap && i <= 2);

        return firstMap;
    },
    loadMaps: function loadMaps() {
        // Enten populeres kortet med manuelle punkter (fra sourcen) eller også henter vi fra datakilde (iPure / XML)
        if (AU.maps.single.maps.length > 0) {
            AU.maps.single.init();
        } else {
            if (typeof window.path !== 'undefined') {

                var dataFunc = function dataFunc(data) {
                    var locations = data.locations;
                    var combinedPoints = [];
                    var name;
                    for (var l = 0; l < locations.length; l++) {
                        name = locations[l].names[0].name;
                        var lat = locations[l].latitude;
                        var lng = locations[l].longitude;
                        var url = locations[l].url;
                        var text = locations[l].text;

                        var points = [];
                        var point = {
                            latitude: lat,
                            longitude: lng,
                            title: name,
                            text: text
                        };
                        points.push(point);
                        combinedPoints.push(point);

                        if ($('#au_map').length > 0) {
                            AU.maps.single.maps.push({
                                name: name,
                                name_en: name,
                                container: '#au_map',
                                mapWidth: AU.maps.single.options.mapWidth,
                                mapHeight: AU.maps.single.options.mapHeight,
                                href: url,
                                points: points,
                                switchmode: 'dropdown',
                                load: false
                            });
                        }
                    }

                    if ($('#au_map2').length > 0) {
                        AU.maps.single.maps.push({
                            name: name,
                            name_en: name,
                            container: '#au_map2',
                            mapWidth: AU.maps.single.options.mapWidth,
                            mapHeight: AU.maps.single.options.mapHeight,
                            href: '',
                            points: combinedPoints,
                            load: true
                        });
                    }

                    AU.maps.single.init();
                };

                $.getJSON(window.path, function (data) {
                    dataFunc(data);
                });
            }
        }

        AU.maps.single.firstMapLoaded = true;
    },
    init: function init() {

        AU.maps.route.directionsService = new google.maps.DirectionsService();
        AU.maps.infoWindow = new google.maps.InfoWindow();
        AU.maps.geocoder = new google.maps.Geocoder();

        var divUsed = false;
        for (var i = 0; i < AU.maps.single.maps.length; i++) {

            var currentMap = AU.maps.single.maps[i];

            currentMap.map = null;
            currentMap.markers = [];

            // Finder ud af, om vi allerede har brugt den angivne div
            for (var k = 0; k < AU.maps.single.mapDivs.length; k++) {
                if (AU.maps.single.mapDivs[k] === currentMap.container) {
                    divUsed = true;
                    break;
                } else {
                    divUsed = false;
                }
            }

            // Hvis vi ikke har brugt div'en, opretter vi kortet
            if (!divUsed) {
                var mapContainer = $(currentMap.container).get(0);
                var options = AU.maps.single.options;

                currentMap.map = AU.maps.create(mapContainer, currentMap.mapWidth, currentMap.mapHeight, options.defaultZoomLevel, AU.maps.single.center, options.scrollWheel, options.draggable, options.clickable, options.streetView);
                AU.maps.single.mapDivs.push(AU.maps.single.maps[i].container);

                if (currentMap.load) {
                    this.setMapContent(currentMap, false);
                }

                $(currentMap.container).after($('<div />', {
                    'class': 'au_maps_switchlink'
                }));

                if (typeof currentMap.switchmode !== 'undefined' && currentMap.switchmode === 'dropdown') {
                    $(currentMap.container + ' + div').append($('<select />'));
                }
            } else {
                // Ellers opretter vi en ny visning på det samme kort/den samme div
                for (var m = 0; m < AU.maps.single.maps.length; m++) {
                    if (AU.maps.single.maps[m].container === AU.maps.single.mapDivs[k]) {
                        currentMap.map = AU.maps.single.maps[m].map;
                        break;
                    }
                }
            }

            if (typeof currentMap.switchmode !== 'undefined') {
                if (currentMap.switchmode === 'links') {

                    var span = $('<span />', {
                        'text': ' | '
                    });

                    var link = $('<a />', {
                        'id': 'au_maps_switchlink_' + i,
                        'href': '#',
                        'text': AU.pageLang === 'da' ? currentMap.name : currentMap.name_en
                    });

                    span.append(link);
                    $(currentMap.container + ' + div').append(span);
                }

                if (currentMap.switchmode === 'dropdown') {
                    var option = $('<option />', {
                        'value': i,
                        'text': AU.pageLang === 'da' ? currentMap.name : currentMap.name_en
                    });

                    if (typeof window.selected !== 'undefined' && (currentMap.name === window.selected || currentMap.name_en === window.selected)) {
                        option.attr('selected', 'selected');
                    }

                    $(currentMap.container + ' + div select').append(option);
                }
            }
        }

        $('.au_maps_switchlink a').click(function (e) {

            e.preventDefault();

            var idSplit = $(this).attr('id').split('au_maps_switchlink_');
            if (idSplit.length === 2) {
                var id = idSplit[1];
                AU.maps.single.switchMode(id);
                $(AU.maps.single.maps[id].container + ' + div a').css('font-weight', 'normal');
                $(this).css('font-weight', 'bold');
            }
        });

        $.each($('.au_maps_switchlink'), function () {
            if ($('a', this).length > 1) {
                $('.au_maps_switchlink span:first-child a').trigger('click');
            }

            if ($('select', this).length > 0) {
                var id = $('select option:selected', this).val();
                AU.maps.single.switchMode(id);
            }
        });

        $('.au_maps_switchlink select').change(function () {
            var id = $(this).val();
            AU.maps.single.switchMode(id);
        });
    },
    switchMode: function switchMode(id) {
        this.setMapContent(maps[id], true);
        if (typeof maps[id].onchangeHybrid !== 'undefined') {
            if (maps[id].onchangeHybrid) {
                maps[id].map.setMapTypeId(google.maps.MapTypeId.HYBRID);
            } else {
                maps[id].map.setMapTypeId('default');
            }
        }
    },
    setMapContent: function setMapContent(map, clear) {
        if (clear) {
            for (var i = 0; i < AU.maps.single.maps.length; i++) {
                if (map.map === AU.maps.single.maps[i].map) {
                    for (var n = 0; n < AU.maps.single.maps[i].markers.length; n++) {
                        AU.maps.single.maps[i].markers[n].setMap(null);
                    }
                    AU.maps.single.maps[i].markers = [];
                }
            }
        }

        if (map.points.length > 0) {
            var centerMap = false;
            var options = AU.maps.single.options;
            for (var j = 0; j < map.points.length; j++) {
                var title = !map.points[j].title ? '' : map.points[j].title;
                var text = !map.points[j].text ? '<p><strong>' + title + '</strong></p>' : map.points[j].text;
                var point = new google.maps.LatLng(map.points[j].latitude, map.points[j].longitude);
                var marker = AU.maps.marker.add(map.map, point, options.image, options.markerDrag, options.markerClick, options.clickPanTo, options.clickZoom, options.clickZoomLevel, AU.maps.infoWindow, text, title);
                map.markers.push(marker);
                if (centerMap) {
                    map.setCenter(point);
                }
            }
            if (map.points.length > 1) {
                AU.maps.setBounds(map.map, [map.markers]);
            } else {
                map.map.setCenter(map.markers[0].position);
                map.map.setZoom(AU.maps.single.options.defaultZoomLevel);
            }
        }

        if (map.href !== null) {
            this.setMapClickEvent(map.map, map.href);
        }
    },
    setMapClickEvent: function setMapClickEvent(map, href) {
        google.maps.event.addListener(map, 'click', function (p) {
            window.location = href;
        });
    }
};

AU.maps.widget = {
    googleMaps: true,
    widgets: [],
    constants: {
        BUILDING: -1,
        DEPARTMENT: -1,
        OVERLAY: -1,
        markerWidth: 24,
        markerHeight: 33,
        overlayMarkerWidth: 24,
        overlayMarkerHeight: 33,
        centerLat: 56.168995,
        centerLng: 10.203113
    },
    options: {
        config: {
            container: '#universe-utility-map',
            mapContainer: 'au_map_widget',
            pin: true,
            image: '',
            defaultZoomLevel: 17,
            markerDrag: false,
            markerClick: false,
            clickPanTo: false,
            clickZoom: false,
            clickZoomLevel: 17
        }
    },
    data: {
        source: {
            data: "//typo3.au.dk/maps/xmltojson.php"
        },
        locations: []
    },
    loadMap: function loadMap(widget) {
        if (widget.mapContainer && this.googleMaps) {
            widget.map = AU.maps.create(widget.mapContainer, '100%', 190, widget.config.defaultZoomLevel, new google.maps.LatLng(56.169046, 10.203107), true, true, false, false);
            widget.loaded = true;
            google.maps.event.trigger(widget.map, 'resize');
        }
    },
    loadData: function loadData(widget) {

        var dataFunc = function dataFunc(data) {

            var w = AU.maps.widget;

            w.constants.BUILDING = data.constants[0].building;
            w.constants.DEPARTMENT = data.constants[0].department;
            w.constants.OVERLAY = data.constants[0].overlay;

            w.data.locations = data.locations;
            w.setAutoCompletes(widget);

            w.checkForSearchQuery(widget);
        };

        var ieVersion = AU.helpers.getInternetExplorerVersion();
        if (ieVersion > 6 && ieVersion < 10) {
            var xdr = window.XDomainRequest ? new XDomainRequest() : null;
            if (xdr !== null) {
                xdr.onload = function () {
                    dataFunc(jQuery.parseJSON(xdr.responseText));
                };

                xdr.onprogress = function () {};

                xdr.open('get', AU.maps.widget.data.source.data);
                xdr.send();
            }
        } else {
            jQuery.getJSON(this.data.source.data, function (data) {
                dataFunc(data);
            });
        }
    },
    setAutoCompletes: function setAutoCompletes(widget) {

        var search = [];
        for (var i = 0; i < this.data.locations.length; i++) {
            var loc = this.data.locations[i];
            for (var n = 0; n < loc.names.length; n++) {
                search.push({
                    id: loc.id,
                    label: loc.names[n].name,
                    value: loc.names[n].name,
                    type: loc.type
                });
            }
        }

        // Lokationer
        var acLocOptions = {
            source: search,
            minLength: 3,
            autoFocus: true,
            select: function select(event, ui) {

                var id = ui.item.id;
                var w = AU.maps.widget;
                if (ui.item.type === w.constants.BUILDING) {
                    w.setBuildingMarker(widget, w.getBuilding(id), true, true, true);
                } else if (ui.item.type === w.constants.DEPARTMENT) {
                    w.setDepartmentMarkers(widget, w.getDepartment(id));
                } else {
                    w.setOverlayMarker(widget, w.getOverlay(id), true, true, true, true);
                }
            }
        };

        // Personsøgning
        var acPeopleOptions = {
            source: function source(request, response) {
                var search = request.term;
                if (typeof String.prototype.trim === "function") {
                    search = search.trim();
                }
                AU.helpers.getXML(AU.ipure.source.searchCrossSite + encodeURI(search), function (data) {
                    response(AU.ipure.getPeople(data));
                });
            },
            minLength: 3,
            autoFocus: true,
            select: function select(event, ui) {
                AU.helpers.getXML(AU.ipure.source.searchDetailsCrossSite + ui.item.url, function (data) {
                    AU.maps.widget.setPersonMarker(widget, data);
                });
            }
        };

        jQuery('.au_maps_widget_acl', widget.container).autocomplete(acLocOptions);
        jQuery('.au_maps_widget_acp', widget.container).autocomplete(acPeopleOptions);
    },
    checkForSearchQuery: function checkForSearchQuery(widget) {
        if (!widget.pin && AU.helpers.getQuerystring('q') !== "") {

            var getBuildingString = function getBuildingString(query) {

                var containsBuildingNumber = function containsBuildingNumber(query) {
                    var re = new RegExp('(\\d{4})', 'ig');
                    return query.match(re);
                };

                var cbn = containsBuildingNumber(query);
                if (cbn !== null) {
                    return cbn[0];
                }

                return "";
            };

            var query = decodeURI(AU.helpers.getQuerystring('q'));
            if (typeof String.prototype.trim === "function") {
                query = query.trim();
            }

            var bs = getBuildingString(query);
            if (bs !== "") {
                var bldg = this.getBuilding(bs);
                if (bldg !== null) {
                    this.setBuildingMarker(widget, bldg, true, true, true);
                    jQuery('.au_maps_widget_acl', widget.container).val((AU.pageLang === "da" ? 'Bygning ' : 'Building ') + bldg.id);
                }
            } else {
                var querySplit = query.split(" ");
                if (querySplit.length > 1 || query.indexOf('+') > -1) {
                    query = query.replace(/\+/g, ' ');
                    AU.helpers.getXML(AU.ipure.source.searchCrossSite + encodeURI(query), function (data) {
                        var people = AU.ipure.getPeople(data);
                        if (people.length === 1) {
                            AU.helpers.getXML(AU.ipure.source.searchDetailsCrossSite + people[0].url, function (data) {
                                AU.maps.widget.setPersonMarker(widget, data);
                                jQuery('.au_maps_widget_acp', widget.container).val(query);
                            });
                        } else if (people.length > 1) {
                            jQuery('.au_maps_widget_acp', widget.container).val(query).focus().autocomplete('search');
                        }
                    });
                }
            }
        }
    },
    clearMarkers: function clearMarkers(widget) {
        for (var i = 0; i < widget.markers.length; i++) {
            widget.markers[i].setMap(null);
        }
        widget.markers = [];
    },
    setBuildingMarker: function setBuildingMarker(widget, building, clearOld, centerMap, showContent) {

        if (AU.maps.widget.googleMaps) {
            if (clearOld) {
                this.clearMarkers(widget);
            }

            var point = new google.maps.LatLng(building.latitude, building.longitude);
            var icon = new google.maps.MarkerImage(widget.config.image, null, null, null, new google.maps.Size(this.constants.markerWidth, this.constants.markerHeight));
            var windowContent = '<p><a href="//www.au.dk/' + (AU.pageLang === "da" ? 'kort' : 'en/map') + '?b=' + building.id + '">' + (AU.pageLang === "da" ? 'Vis detajler på bygningskortet.' : 'Show details on the building map.') + '</a></p>';

            var marker = AU.maps.marker.add(widget.map, point, icon, false, false, false, false, widget.config.defaultZoomLevel, AU.maps.infoWindow, windowContent, building.names[0].name);
            widget.markers.push(marker);

            if (centerMap) {
                widget.map.setCenter(point);
            }
        }

        widget.content.empty();
        if (showContent) {
            widget.content.append('<h2><strong>' + (AU.pageLang === "da" ? 'Bygning ' : 'Building ') + building.id + '</strong> - ' + building.address + '</h2>');
        }
    },
    setDepartmentMarkers: function setDepartmentMarkers(widget, department) {
        AU.helpers.getXML(AU.ipure.source.searchDetailsWrapper + department.departmentsdetails, function (data) {
            var clearOld = false;
            var centerMap = false;

            var w = AU.maps.widget;

            var addressNode = jQuery(data).find('address');
            var street = addressNode.attr('street');
            var zip = addressNode.attr('zip');
            var city = addressNode.attr('city');
            var address = street + ", " + zip + " " + city;

            var buildingNode = jQuery(data).find('building');
            var buildings = [];

            // Bygninger ligger ikke direkte som en datakilde
            for (var i = 0; i < buildingNode.length; i++) {
                var building = w.getBuilding(jQuery(buildingNode[i]).attr('number'));
                if (building !== null) {
                    buildings.push(building);
                }
            }

            // Løber alle bygninger igennem og placerer markører
            if (buildings.length > 0) {
                for (var k = 0; k < buildings.length; k++) {
                    w.setBuildingMarker(widget, buildings[k], clearOld, centerMap, false);
                }
                if (AU.maps.widget.googleMaps) {
                    AU.maps.setBounds(widget.map, [widget.markers]);
                }
            } else {
                if (AU.maps.widget.googleMaps) {
                    // Hvis vi ikke finder nogle bygninger, AU.maps.geocoder vi på baggrund af enhedens adresse
                    AU.maps.geocoder.geocode({
                        'address': address
                    }, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            var point = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                            var options = widget.config;
                            var icon = new google.maps.MarkerImage(options.image, null, null, null, new google.maps.Size(w.constants.markerWidth, w.constants.markerHeight));
                            var marker = AU.maps.marker.add(widget.map, point, icon, false, false, false, false, options.defaultZoomLevel, AU.maps.infoWindow, "", "");
                            widget.markers.push(marker);
                            widget.map.setCenter(point);
                        }
                    });
                }
            }

            widget.content.empty();
            widget.content.append('<h2>' + department.names[0].name + '</h2><p><a href="//www.au.dk/' + (AU.pageLang === "da" ? 'kort' : 'en/map') + '?e=' + department.id + '">' + (AU.pageLang === "da" ? 'Vis detaljer om enheden på bygningskortet.' : 'Show details for department on the building map.') + '</a></p>');
        });
    },
    setOverlayMarker: function setOverlayMarker(widget, place, clearOld, centerMap) {

        var windowContent = '<p><a href="//www.au.dk/' + (AU.pageLang === "da" ? 'kort' : 'en/map') + '?o=' + place.groupid + '">' + (AU.pageLang === "da" ? 'Vis alle "' + place.groupname + '"-lokationer på bygningskortet.' : 'Show all "' + place.groupname + '" locations on building map.') + '</a></p>';

        if (AU.maps.widget.googleMaps) {
            if (clearOld) {
                this.clearMarkers(widget);
            }

            var point = new google.maps.LatLng(place.latitude, place.longitude);

            var icon;
            if (place.icon.indexOf('generic') === -1) {
                icon = new google.maps.MarkerImage(place.icon, null, null, null, new google.maps.Size(this.constants.overlayMarkerWidth, this.constants.overlayMarkerHeight));
            } else {
                icon = new google.maps.MarkerImage(place.icon, null, null, null, new google.maps.Size(this.constants.markerWidth, this.constants.markerHeight));
            }

            var marker = AU.maps.marker.add(widget.map, point, icon, false, false, false, false, widget.config.defaultZoomLevel, AU.maps.infoWindow, windowContent, "", place.id);
            widget.markers.push(marker);

            if (centerMap) {
                widget.map.setCenter(point);
            }
        }

        widget.content.empty();
        widget.content.append('<h2>' + place.names[0].name + '</h2>' + windowContent);
    },
    setPersonMarker: function setPersonMarker(widget, data) {
        AU.maps.widget.clearMarkers(widget);
        AU.ipure.getPersonDetails(data, function (personHTML, buildingNumber, email, officePhone, address, centerMap) {

            var w = AU.maps.widget;
            var icon = w.googleMaps ? new google.maps.MarkerImage(widget.config.image, null, null, null, new google.maps.Size(w.constants.markerWidth, w.constants.markerHeight)) : null;
            var iw = w.googleMaps ? new google.maps.InfoWindow() : null;
            var windowContent = '<p><a href="//www.au.dk/' + (AU.pageLang === "da" ? 'kort' : 'en/map') + '?p=' + email + '">' + (AU.pageLang === "da" ? 'Vis på bygningskortet.' : 'Show on the building map.') + '</a></p>';
            if (typeof buildingNumber === "undefined" && w.googleMaps) {
                AU.maps.geocodePersonAddress(widget.map, widget.config, icon, address, centerMap, iw, windowContent, function (marker, point, centerMap, infoWindow, windowContent) {
                    widget.content.empty();
                    widget.content.append(personHTML);
                    widget.markers.push(marker);

                    if (w.googleMaps) {
                        if (centerMap) {
                            widget.map.setCenter(point);
                        } else {
                            AU.maps.setBounds(widget.map, [widget.markers]);
                        }
                    }
                });
            } else {

                if (w.googleMaps) {
                    var building = w.getBuilding(buildingNumber);
                    w.setBuildingMarker(widget, building, true, centerMap, false);
                }
                widget.content.empty();
                widget.content.append(personHTML);
            }
        });
    },
    getBuilding: function getBuilding(number) {
        var building = null;
        for (var i = 0; i < this.data.locations.length; i++) {
            if (this.data.locations[i].type === this.constants.BUILDING && this.data.locations[i].id === number.toString()) {
                building = this.data.locations[i];
                break;
            }
        }
        return building;
    },
    getDepartment: function getDepartment(departmentId) {
        var department = null;
        for (var i = 0; i < this.data.locations.length; i++) {
            if (this.data.locations[i].type === this.constants.DEPARTMENT && this.data.locations[i].id === departmentId.toString()) {
                department = this.data.locations[i];
                break;
            }
        }
        return department;
    },
    getOverlay: function getOverlay(id) {
        var place;
        for (var i = 0; i < this.data.locations.length; i++) {
            if (this.data.locations[i].type === this.constants.OVERLAY && this.data.locations[i].id === id.toString()) {
                place = this.data.locations[i];
            }
        }
        return place;
    },
    mergeOptions: function mergeOptions(widget) {
        if (!widget.hasOwnProperty('config')) {
            widget.config = {};
        }

        for (var conf in this.options.config) {
            if (!widget.config.hasOwnProperty(conf)) {
                widget.config[conf] = this.options.config[conf];
            }
        }
    },
    init: function init() {
        if (typeof google === "undefined") {
            AU.maps.widget.googleMaps = false;
        }

        if (AU.maps.widget.googleMaps) {

            AU.maps.route.directionsService = new google.maps.DirectionsService();
            AU.maps.infoWindow = new google.maps.InfoWindow();
            AU.maps.geocoder = new google.maps.Geocoder();
        }

        for (var i = 0; i < this.widgets.length; i++) {
            var wdgt = this.widgets[i];
            this.mergeOptions(wdgt);
            wdgt.container = jQuery(wdgt.config.container);
            wdgt.mapContainer = document.getElementById(wdgt.config.mapContainer);
            wdgt.content = jQuery('.au_maps_widget_content', wdgt.container);
            wdgt.markers = [];
            wdgt.loaded = false;

            if (!wdgt.config.pin) {
                this.loadMap(wdgt);
                this.loadData(wdgt);
            }
        }
    }
};
'use strict';

AU.piechart = {
    options: {
        config: {
            container: '.pie-container',
            canvas: '#pie1',
            width: 250,
            height: 250,
            colors: ['#b9e5fb', '#6ecff6', '#00bdf2', '#00aeef', '#008bbf', '#006991', '#004563', '#37373a', '#4c4d4f', '#6d6e71', '#818285', '#9d9fa2', '#bcbdc0'],
            data: [{ value: 50, label: 'Data 1' }, { value: 50, label: 'Data 2' }]
        }
    },
    mergeOptions: function mergeOptions(pie) {
        if (!pie.hasOwnProperty('config')) {
            pie.config = {};
        }

        for (var conf in this.options.config) {
            if (!pie.config.hasOwnProperty(conf)) {
                pie.config[conf] = this.options.config[conf];
            }
        }

        if (!pie.hasOwnProperty('data')) {
            pie.data = {};
        }

        for (var d in this.options.data) {
            if (!pie.data.hasOwnProperty(d)) {
                pie.data[d] = this.options.data[d];
            }
        }
    },
    highlight: function highlight(col, amt) {

        var usePound = false;

        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);
        var r = (num >> 16) + amt;

        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
        }

        var b = (num >> 8 & 0x00FF) + amt;

        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
        }

        var g = (num & 0x0000FF) + amt;

        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }

        return (usePound ? '#' : '') + (g | b << 8 | r << 16).toString(16);
    },
    getData: function getData(pie) {
        var data = [];
        var colorIndex = 0;
        for (var i = 0; i < pie.config.data.length; i++) {
            var pd = pie.config.data[i];

            if (colorIndex === pie.config.colors.length - 1) {
                colorIndex = 0;
            }

            data.push({
                value: pd.value,
                label: pd.label,
                color: pie.config.colors[colorIndex],
                highlight: this.highlight(pie.config.colors[colorIndex], 10)
            });

            colorIndex++;
        }

        return data;
    },
    createLegend: function createLegend(pie, canvas) {
        var helpers = Chart.helpers;
        var legendHolder = document.createElement('div');
        legendHolder.innerHTML = pie.generateLegend();
        helpers.each(legendHolder.firstChild.childNodes, function (legendNode, index) {
            helpers.addEvent(legendNode, 'mouseover', function () {
                var activeSegment = pie.segments[index];
                activeSegment.save();
                activeSegment.fillColor = activeSegment.highlightColor;
                pie.showTooltip([activeSegment]);
                activeSegment.restore();
            });
        });
        helpers.addEvent(legendHolder.firstChild, 'mouseout', function () {
            pie.draw();
        });
        canvas.parentNode.parentNode.appendChild(legendHolder.firstChild);
    },
    pies: [],
    init: function init() {
        for (var i = 0; i < this.pies.length; i++) {
            var pie = this.pies[i];
            if (!pie.loaded) {
                pie.id = i;
                this.mergeOptions(pie);
                var mountNode = document.querySelector(pie.config.container);
                var visible = mountNode && AU.helpers.isElementPartlyInViewport(mountNode) && getComputedStyle(mountNode)['visibility'] != 'hidden';
                if (visible) {
                    $(pie.config.container).append('<div><canvas id="' + pie.config.canvas.replace('#', '') + '" width="' + pie.config.width + '" height="' + pie.config.height + '"></canvas></div>');
                    pie.canvas = document.querySelector(pie.config.canvas);
                    var chartPie = new Chart(pie.canvas.getContext('2d')).Pie(this.getData(pie), { tooltipTemplate: "<%if (label){%><%=label.length > 15 ? (label.substring(0, 15) + '...') : label %> : <%}%><%= value %>%", animation: true });
                    this.createLegend(chartPie, pie.canvas);
                    pie.loaded = true;
                }
            }
        }

        var pieLegend = $('.pie-legend');
        if (pieLegend !== null) {
            pieLegend.addClass('resetlist');
        }
    }
};

AU.addScrollFunction(function () {
    AU.piechart.init();
});
'use strict';

// Default søgeboks til medarbejderportaler til utility-box
var DEFAULT_util_staffportal_da = {
    config: {
        container: '#au_databox_staff_utility',
        alphabet: 0,
        buttons: [{
            text: 'Institutter',
            link: ''
        }, {
            text: 'Fakulteter',
            link: ''
        }, {
            text: 'Fællesadministrationen',
            link: ''
        }, {
            text: 'Øvrige enheder',
            link: ''
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        color: '#37a0cb',
        overlay: false
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/6'
    }
};
var DEFAULT_util_staffportal_en = {
    config: {
        container: '#au_databox_staff_utility',
        alphabet: 0,
        buttons: [{
            text: 'Departments',
            link: ''
        }, {
            text: 'Faculties',
            link: ''
        }, {
            text: 'Other units',
            link: ''
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        color: '#37a0cb',
        overlay: false
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/7'
    }
};

// Default søgeboks til institutter til utility-box
var DEFAULT_util_department_da = {
    config: {
        container: '#au_databox_unit_utility',
        alphabet: 0,
        buttons: [{
            text: 'Arts',
            link: 'http://arts.au.dk'
        }, {
            text: 'Health',
            link: 'http://health.au.dk'
        }, {
            text: 'Natural Sciences',
            link: 'http://nat.au.dk'
        }, {
            text: 'Technical Sciences',
            link: 'http://tech.au.dk'
        }, {
            text: 'Aarhus BSS',
            link: 'http://bss.au.dk'
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        overlay: false,
        color: '#003260'
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/47'
    }
};
var DEFAULT_util_department_en = {
    config: {
        container: '#au_databox_unit_utility',
        alphabet: 0,
        buttons: [{
            text: 'Arts',
            link: 'http://arts.au.dk/en/'
        }, {
            text: 'Health',
            link: 'http://health.au.dk/en/'
        }, {
            text: 'Natural Sciences',
            link: 'http://nat.au.dk/en/'
        }, {
            text: 'Technical Sciences',
            link: 'http://tech.au.dk/en/'
        }, {
            text: 'Aarhus BSS',
            link: 'http://bss.au.dk/en/'
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        overlay: false,
        color: '#003260'
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/52'
    }
};

// Default alphabox med medarbejderservice til utility-box
var DEFAULT_util_staffservice_da = {
    config: {
        container: '#au_alphabox_staff_utility',
        title: 'Find værktøjer, vejledninger og serviceydelser',
        url: 'http://medarbejdere.au.dk/administration/',
        description: 'Find flere informationer om',
        links: [{
            title: 'HR',
            url: 'http://medarbejdere.au.dk/administration/hr/'
        }, {
            title: 'IT',
            url: 'http://medarbejdere.au.dk/administration/it/'
        }, {
            title: 'Økonomi',
            url: 'http://medarbejdere.au.dk/administration/oekonomi/'
        }, {
            title: 'Bygninger',
            url: 'http://medarbejdere.au.dk/administration/bygninger/'
        }, {
            title: 'Kommunikation',
            url: 'http://medarbejdere.au.dk/administration/kommunikation/'
        }, {
            title: 'Studieadm.',
            url: 'http://medarbejdere.au.dk/administration/studieadministration/'
        }, {
            title: 'Forskningspraksis',
            url: 'http://medarbejdere.au.dk/administration/forskning-talent/'
        }, {
            title: 'Internationalt',
            url: 'http://medarbejdere.au.dk/administration/internationalt/'
        }],
        noResultsAlphabet: 'Fandt du ikke, hvad du søgte?',
        noResultsSearch: 'Din søgning gav ingen resultater. Prøv nogle andre ord.',
        noResultsUri: 'https://medarbejdere.au.dk/administration/emneindeks'
    },
    search: {
        placeHolder: 'Søg i værktøjer m.m.'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/8?itemsOnly=true'
    }
};
var DEFAULT_util_staffservice_en = {
    config: {
        container: '#au_alphabox_staff_utility',
        title: 'Find tools, guidelines and services',
        url: 'http://medarbejdere.au.dk/en/administration/',
        description: 'Find more information about',
        links: [{
            title: 'HR',
            url: 'http://medarbejdere.au.dk/en/administration/hr/'
        }, {
            title: 'IT',
            url: 'http://medarbejdere.au.dk/en/administration/it/'
        }, {
            title: 'Finance',
            url: 'http://medarbejdere.au.dk/en/administration/oekonomi/'
        }, {
            title: 'Buildings',
            url: 'http://medarbejdere.au.dk/en/administration/bygninger/'
        }, {
            title: 'Communication',
            url: 'http://medarbejdere.au.dk/en/administration/kommunikation/'
        }, {
            title: 'Studies adm.',
            url: 'http://medarbejdere.au.dk/en/administration/studieadministration/'
        }, {
            title: 'Research Practice',
            url: 'http://medarbejdere.au.dk/en/administration/researchandtalent/'
        }, {
            title: 'International',
            url: 'http://medarbejdere.au.dk/en/administration/international/'
        }],
        noResultsAlphabet: 'Didn\'t find what you were looking for?',
        noResultsSearch: 'Your search did not return any results. Please try other words.',
        noResultsUri: 'https://medarbejdere.au.dk/en/administration/index-for-staff-service'
    },
    search: {
        placeHolder: 'Search for tools, etc.'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/9?itemsOnly=true'
    }
};

// Default søgeboks til ph.d.-portaler til utility-box
var DEFAULT_util_phdportal_da = {
    config: {
        container: '#au_databox_phd_utility',
        alphabet: 0,
        buttons: [{
            text: 'Arts',
            link: 'https://phd.arts.au.dk'
        }, {
            text: 'Health',
            link: 'https://phd.health.au.dk/'
        }, {
            text: 'Natural Sciences',
            link: 'https://phd.nat.au.dk/'
        }, {
            text: 'Technical Sciences',
            link: 'https://phd.tech.au.dk/'
        }, {
            text: 'Aarhus BSS',
            link: 'https://bss.au.dk/phd/'
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        overlay: false,
        color: '#003260'
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/50'
    }
};
var DEFAULT_util_phdportal_en = {
    config: {
        container: '#au_databox_phd_utility',
        alphabet: 0,
        buttons: [{
            text: 'Arts',
            link: 'https://phd.arts.au.dk'
        }, {
            text: 'Health',
            link: 'https://phd.health.au.dk/'
        }, {
            text: 'Natural Sciences',
            link: 'https://phd.nat.au.dk/'
        }, {
            text: 'Technical Sciences',
            link: 'https://phd.tech.au.dk/'
        }, {
            text: 'Aarhus BSS',
            link: 'https://bss.au.dk/phd/'
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        overlay: false,
        color: '#003260'
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/50'
    }
};

// Default alphabox med studieportaler til utility-box
var DEFAULT_util_student_da = {
    config: {
        container: '#au_alphabox_student_utility',
        title: 'Studieportaler',
        url: 'http://studerende.au.dk',
        links: []
    },
    search: {
        placeHolder: 'Søg efter studieportal'
    },
    background: {
        color: '#2a4a0f',
        linkColor: '#8bad3f'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/2?itemsOnly=true'
    }
};
var DEFAULT_util_student_en = {
    config: {
        container: '#au_alphabox_student_utility',
        title: 'Study portals',
        url: 'http://studerende.au.dk/en/',
        links: []
    },
    search: {
        placeHolder: 'Search for Study portal'
    },
    background: {
        color: '#2a4a0f',
        linkColor: '#8bad3f'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/4?itemsOnly=true'
    }
};

// Default alphabox med medarbejderservice til medarbejdere.au.dk (forside m.m.)
var DEFAULT_staff_staffservice_da = {
    config: {
        container: '#au_alphabox_1',
        title: 'Find værktøjer, vejledninger og serviceydelser',
        url: 'http://medarbejdere.au.dk/administration/',
        description: 'Find flere informationer om',
        links: [{
            title: 'HR',
            url: 'http://medarbejdere.au.dk/administration/hr/'
        }, {
            title: 'IT',
            url: 'http://medarbejdere.au.dk/administration/it/'
        }, {
            title: 'Økonomi',
            url: 'http://medarbejdere.au.dk/administration/oekonomi/'
        }, {
            title: 'Bygninger',
            url: 'http://medarbejdere.au.dk/administration/bygninger/'
        }, {
            title: 'Kommunikation',
            url: 'http://medarbejdere.au.dk/administration/kommunikation/'
        }, {
            title: 'Studieadm.',
            url: 'http://medarbejdere.au.dk/administration/studieadministration/'
        }, {
            title: 'Forskningspraksis',
            url: 'http://medarbejdere.au.dk/administration/forskning-talent/'
        }, {
            title: 'Internationalt',
            url: 'http://medarbejdere.au.dk/administration/internationalt/'
        }],
        noResultsAlphabet: 'Fandt du ikke, hvad du søgte?',
        noResultsSearch: 'Din søgning gav ingen resultater. Prøv nogle andre ord.',
        noResultsUri: 'https://medarbejdere.au.dk/administration/emneindeks'
    },
    search: {
        placeHolder: 'Søg i værktøjer m.m.'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/8?itemsOnly=true'
    }
};
var DEFAULT_staff_staffservice_en = {
    config: {
        container: '#au_alphabox_1',
        title: 'Find tools, guidelines and services',
        url: 'http://medarbejdere.au.dk/en/administration/',
        description: 'Find more information about',
        links: [{
            title: 'HR',
            url: 'http://medarbejdere.au.dk/en/administration/hr/'
        }, {
            title: 'IT',
            url: 'http://medarbejdere.au.dk/en/administration/it/'
        }, {
            title: 'Finance',
            url: 'http://medarbejdere.au.dk/en/administration/oekonomi/'
        }, {
            title: 'Buildings',
            url: 'http://medarbejdere.au.dk/en/administration/bygninger/'
        }, {
            title: 'Communication',
            url: 'http://medarbejdere.au.dk/en/administration/kommunikation/'
        }, {
            title: 'Studies adm.',
            url: 'http://medarbejdere.au.dk/en/administration/studieadministration/'
        }, {
            title: 'Research Practice',
            url: 'http://medarbejdere.au.dk/en/administration/researchandtalent/'
        }, {
            title: 'International',
            url: 'http://medarbejdere.au.dk/en/administration/international/'
        }],
        noResultsAlphabet: 'Didn\'t find what you were looking for?',
        noResultsSearch: 'Your search did not return any results. Please try other words.',
        noResultsUri: 'https://medarbejdere.au.dk/en/administration/index-for-staff-service'
    },
    search: {
        placeHolder: 'Search for tools, etc.'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/9?itemsOnly=true'
    }
};
'use strict';

$.ajaxSetup({
    cache: true
});
var CludoSearch;
AU.search = {
    init: function init(searchUrl) {
        // Cludo
        var primaryDanish = typeof window.primaryDanish == 'undefined' ? true : window.primaryDanish;
        var baseUrlAppend = primaryDanish ? AU.pageLang == 'da' ? '' : 'en/' : AU.pageLang == 'da' ? 'da/' : '';
        var baseUrl = jQuery('base') == null ? location.host : jQuery('base').attr('href') + baseUrlAppend;
        if (baseUrl.indexOf('http') === -1) {
            baseUrl = 'https:' + baseUrl;
        }
        var engineId = typeof window.cludoEngineId == 'undefined' || window.cludoEngineId === 0 ? AU.pageLang == 'da' ? 2151 : 8956 : window.cludoEngineId;
        var filters = baseUrl.indexOf('www.au.dk') > -1 || typeof window.cludoEngineId != 'undefined' && window.cludoEngineId > 0 ? {} : { 'DomainName': [baseUrl] };
        jQuery.getScript('//customer.cludo.com/scripts/bundles/search-script.min.js', function () {
            var cludoSettings = {
                customerId: 511,
                engineId: engineId,
                searchUrl: searchUrl,
                searchInputs: ['cludo-search-form', 'cludo-search-content-form', 'cludo-search-mobile-form'],
                initSearchBoxText: '',
                loading: "<img src='//customer.cludo.com/img/balls.svg' alt='Loading' class='loading' role='progressbar' />",
                endlessScroll: { stopAfterPage: 3, resultsPerPage: 10, bottomOffset: 700 },
                language: AU.pageLang,
                disableAutocomplete: typeof window.disableCludoAutocomplete !== 'undefined' && window.disableCludoAutocomplete,
                filters: filters,
                customCallbackAfterSearch: function customCallbackAfterSearch() {
                    AU.search.checkForBanners();
                }
            };
            CludoSearch = new Cludo(cludoSettings);
            CludoSearch.init();

            jQuery('.cludo-searchall a').on('click', function (e) {
                e.preventDefault();
                var href = jQuery(this).attr('href');
                var hash = location.hash;
                location.href = href + hash;
            });
        });
        jQuery('#cludo-search-form-input').on('focus', function () {
            jQuery('#cludo-search-form').addClass('active');
        }).on('focusout', function () {
            jQuery('#cludo-search-form').removeClass('active');
        });
    },
    checkForBanners: function checkForBanners() {
        var banners = jQuery('.cludo-banner .aubanner');
        banners.each(function () {
            var links = jQuery('a', this);
            if (links.length > 0) {
                jQuery(this).wrapInner('<a href="' + links.eq(0).attr('href') + '"></a>');
                links.hide();
            }
        });
    }
};
'use strict';

/**
 * Titel: INIT
 * Beskrivelse: entry point for initialisering af funktioner fra de øvrige scripts - vi bestræber os på kun at have én jQuery(document).ready i "master scriptet"
 * Status: er kun i mindre grad blevet modificeret fra 2011/2015-udgaven
 */

(function () {
    AU.cookies.initCookieScript();

    jQuery(document).ready(function () {

        jQuery(document).foundation(); // Fordi det er fuldstændig utænkeligt, at vi kan få fjernet Prototype fra pure.au.dk...

        if (window.AUCDNPATH) {
            AU.cdn = window.AUCDNPATH;
        }

        AU.bss = document.querySelector('body').classList.contains('aarhus-bss');

        // Vi bruger lang attributten til at afgøre, hvilket sprog vi skal bruge
        var langAttr = document.querySelector('html').getAttribute('lang');
        if (typeof langAttr !== 'undefined' && langAttr.indexOf('da') === -1) {
            AU.pageLang = 'en';
            AU.ipure.source.searchDetailsCrossSite = '//ipure.nfit.au.dk/current/en_GB/lno/';
        }

        var n = AU.navigation;

        n.menu.setClick();
        n.menu.setUtility();
        n.menu.setBreadcrumb();
        n.menu.addExtraMenuItem();
        n.menu.setTopBar();
        if (!AU.bss) {
            n.search.setClick();
        }
        n.frontpage.setTargetGroups();

        AU.alphabox.boxes.push(AU.pageLang === 'da' ? DEFAULT_staff_staffservice_da : DEFAULT_staff_staffservice_en);

        AU.cookies.strings.header = AU.pageLang === 'da' ? 'Hvis du klikker videre på siden, <strong>accepterer du vores brug af cookies</strong>.' : 'By continuing to use this site<strong> you agree to our cookie policy</strong>.';
        AU.cookies.strings.paragraph = AU.pageLang === 'da' ? 'Aarhus Universitet bruger cookies til at udarbejde statistik og i forbindelse med annoncering og services fra sociale medier, som vi benytter. <span class="hide-for-small-only">Klikker du videre på siden, accepterer du, at der sættes cookies til disse formål. <a href="https://www.au.dk/om/profil/cookies/">Læs mere om cookies på au.dk, herunder hvordan du fravælger brugen af cookies</a>.</span><span class="show-for-small-only"><a href="https://www.au.dk/om/profil/cookies/">Læs mere</a>.</span>' : 'Aarhus University uses cookies to collect statistical data and in connection with advertising and services from social media that we use. <span class="hide-for-small-only">By continuing to use this site, you agree to our use of cookies for these purposes. <a href="https://international.au.dk/about/profile/cookies-policy/">Read more about cookies on au.dk and how to block these</a>.</span><span class="show-for-small-only"><a href="https://international.au.dk/about/profile/cookies-policy/">Read more</a>.</span>';
        AU.cookies.strings.button = AU.pageLang === 'da' ? 'Acceptér og luk' : 'Accept and close';

        AU.cookies.init();
        AU.design.setFocusFieldIds();
        AU.design.setBannerElementsReady();
        AU.design.setBuildingLinks('.address p');
        AU.design.setCollapse();
        AU.design.setHero();
        AU.design.setMagnificPopup();
        AU.design.setTopWarning();

        var s = AU.social;
        if (jQuery('.au_news-single-item .addthis_toolbox').length > 0) {
            jQuery('.au_news-single-item').css('position', 'relative');
            s.disableAddThisForOldNews();
            s.sharers.push(s.newsSharer);
        }

        s.init();

        AU.databox.init();
        AU.flowbox.init();
        AU.alphabox.init();
        AU.piechart.init();
        AU.news.feeds = window.newsLists;
        AU.news.init();

        if (window.cludoDanishPath && window.cludoEnglishPath && (typeof window.defaultTracking === 'undefined' || window.defaultTracking)) {
            AU.search.init(AU.pageLang == 'da' ? window.cludoDanishPath : window.cludoEnglishPath);
        }

        var cmForms = jQuery('.js-cm-form');
        cmForms.each(function (i, form) {
            var privacyEmail = jQuery('input[name="cm-privacy-email"]', form);
            if (privacyEmail.length === 0) {
                jQuery(form).append('<input type="checkbox" id="cm-privacy-email" name="cm-privacy-email" style="display: none;" />');
                jQuery(form).append('<input type="hidden" id="cm-privacy-email-hidden" name="cm-privacy-email-hidden" value="true" />');
            }
        });

        // Alle funktioner, som er pushet til ready-objektet, løbes igennem
        for (var i = 0; i < AU.ready.length; i++) {
            if (typeof AU.ready[i] === 'function') {
                try {
                    // Attempt to execute the function
                    AU.ready[i]();
                } catch (e) {
                    // Catch any error and log it, but don't stop execution
                    console.error("Error in AU.ready function loop (au-init.js) at index: " + i, e);
                }
            }
        }

        if (typeof findWidgetInit === 'function') {
            var dropdownWidgetLoaded = false;
            jQuery(document).on('show.zf.dropdown', function () {
                if (!dropdownWidgetLoaded) {
                    findWidgetInit('header .find-container');
                    dropdownWidgetLoaded = true;
                }
            });
        }

        if ((typeof AU.basic === 'undefined' || !AU.basic) && (typeof window.defaultTracking === 'undefined' || window.defaultTracking)) {
            // Siteimprove
            (function () {
                var sz = document.createElement('script');
                sz.type = 'text/plain';
                sz.async = true;
                sz.setAttribute('data-cookiescript', 'accepted');
                sz.setAttribute('data-cookiecategory', 'performance');
                sz.src = '//siteimproveanalytics.com/js/siteanalyze_4573657.js';
                var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(sz, s);
            })();
        }
    });

    jQuery(window).on('load', function () {
        AU.design.setBannerElementsLoad();

        for (var i = 0; i < AU.load.length; i++) {
            if (typeof AU.load[i] === 'function') {
                AU.load[i]();
            }
        }
    });

    jQuery(window).on('resize', function () {
        for (var i = 0; i < AU.resize.length; i++) {
            if (typeof AU.resize[i] === 'function') {
                AU.resize[i]();
            }
        }
    });

    jQuery(window).on('scroll', function () {
        for (var i = 0; i < AU.scroll.length; i++) {
            if (typeof AU.scroll[i] === 'function') {
                AU.scroll[i]();
            }
        }
    });
})();